
<script>
import { defineComponent } from 'vue'
import { getCurrentYear } from '@kit/utils/Formats'

export default defineComponent({
  name: "Footer",
  setup() {
    return { 
      getCurrentYear
    }
  }
});
</script>

<template>
    <div class="footer wf-section">
      <div class="container w-container">
        <div class="footer-row w-row">
          <div class="footer-col-left w-col w-col-6">
            <div class="footer-text">Copyright {{ getCurrentYear() }} © Golden Oaks Motel. Website by: <a href="http://www.centerpointdesigns.com/" target="_blank" class="link">CenterPointDesigns</a>
            </div>
            <div class="footer-text small">All Rights Reserved.</div>
          </div>
          <div class="footer-col-right w-col w-col-6">
            <a href="#" class="footer-social-block w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb62312cee196d9da006f6_559f866bbcccd97730700260_Icon-facebook.png" alt="" class="nav-social-icon" />
            </a>
            <a href="#" class="footer-social-block w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb62312cee19a206a006f5_55d5b366fa59c51977887dd3_Icon-twitter.png" alt="" class="nav-social-icon" />
            </a>
            <a href="#" class="footer-social-block w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb62312cee1975caa006f4_55d5b37ab4ce9258656a0bde_Icon-linkedin-white.png" alt="" class="nav-social-icon" />
            </a>
            <a href="#" class="footer-social-block w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb667b0d861d100bea3303_001-instagram.svg" alt="" class="nav-social-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
</template>