<script>
import { defineComponent, watch, onMounted, onUnmounted, ref, inject, computed } from 'vue'
import "@project/assets/css/goldenoaksmotel.4628b9fc8.css"
import "@kit/assets/css/superbox-core-x.css"
import "@project/assets/css/index.css"
import { inBrowser, SSR, incrementResize, setMobileThresholdFromApp } from '@kit/utils/EnvironmentHelper'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { addEvent } from '@kit/utils/EventBus'
import { pathname } from '@kit/utils/Formats'
import NavBar from '@components/NavBar.vue'
import Footer from '@components/Footer.vue'
import '@images/favicon.png'


const wCtx = typeof window != 'undefined' ? window : null

export default defineComponent({
  name: "GoldenOaksApp",
  setup() {

    let dCache = null
    let dInterval = null
    const store = useStore()
    const hydrate = inject("hydrate")
    const route = useRoute()

    const mediaQuery = wCtx ? wCtx.matchMedia('(max-width : 766px)') : null
    
    const atThreshold = ref(false)

    const debounced = (value) => {
      atThreshold.value = value
    }

    const debouncer = (value) => {
      if(!dInterval) {
        debounced(value)

        dInterval = setInterval(() => {
          if(dCache !== null) {
            debounced(dCache)
            dCache = null
          } else {
            clearInterval(dInterval)
            dInterval = null
          }
        },500)
        
      } else {
        dCache = value
      }
    }

    const screenResized = () => {
      incrementResize()
      const atThreshold = mediaQuery.matches
      setMobileThresholdFromApp(atThreshold)
      debouncer(mediaQuery.matches)
    }


    onMounted(async() => {
      if(wCtx) {

        addEvent("navigateToNewPage", ( info ) => {
          if(info.resetScrollPosition) {
            wCtx.scrollTo(0,0)
          }
        })

        wCtx.addEventListener('resize', screenResized)
        screenResized()

      }
    })
    onUnmounted(() => {
      if(inBrowser) {
        wCtx.removeEventListener('resize', screenResized)
      }
    })

    hydrate({
      metadata(meta) {
        meta.addMetaItem([
          { charset:'utf-8' },
          { name:'viewport', content: 'width=device-width, initial-scale=1.0' },
          { "http-equiv":'X-UA-Compatible', content: 'IE=edge'},
          { property:"og:type", content:"website" },
        ])
        meta.addHTMLAttribute({ name: "data-wf-site", value:"60352597f09f4641fee5d798" })
        meta.addHTMLAttribute({ name: "data-wf-page", value:"60352597f09f46a295e5d799" })
        meta.addHTMLAttribute({ name: "data-wf-domain", value:"www.rviha.org" })
        meta.addHTMLAttribute({ name: "lang", value:"en" })
        meta.addHTMLAttribute({ name: "class", value:"w-mod-js wf-merriweather-n3-active wf-merriweather-i3-active wf-merriweather-n4-active wf-merriweather-i4-active wf-merriweather-n7-active wf-merriweather-i7-active wf-merriweather-n9-active wf-merriweather-i9-active wf-oswald-n2-active wf-oswald-n3-active wf-oswald-n4-active wf-oswald-n5-active wf-oswald-n6-active wf-oswald-n7-active wf-lato-n1-active wf-lato-i1-active wf-lato-n3-active wf-lato-i3-active wf-lato-n4-active wf-lato-i4-active wf-lato-n7-active wf-lato-i7-active wf-lato-n9-active wf-lato-i9-active wf-opensans-n3-active wf-opensans-i3-active wf-opensans-n4-active wf-opensans-i4-active wf-opensans-n6-active wf-opensans-i6-active wf-opensans-n7-active wf-opensans-i7-active wf-opensans-n8-active wf-opensans-i8-active wf-droidsans-n4-active wf-droidsans-n7-active wf-greatvibes-n4-active wf-active"})
        //meta.addAppAttribute({ name: "class", value: "page-content" })
        meta.addBodyAttribute({ name:"class", value:"body" })
        //meta.addBodyAttribute({ name:"data-wf-spring-theme-render", value:"wf-j9hqn0"})
        meta.addFavIcon(pathname('@images/favicon.png'))
      },
      extraJS:[
        { head: true, src: "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js" },
        { 
          head:true, 
          code: `WebFont.load({  google: {    families: ["Merriweather:300,300italic,400,400italic,700,700italic,900,900italic","Oswald:200,300,400,500,600,700","Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic","Open Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic","Droid Sans:400,700","Great Vibes:400"]  }});`
        },

      ],
      //externalCSS: ["https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic%7CLato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic%7CMerriweather:300,300italic,400,400italic,700,700italic,900,900italic%7COpen+Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic%7CPoppins:100,200,300,regular,500,600,700,800,900%7CPlayfair+Display:regular,500,600,700,800,900,italic,500italic,600italic,700italic,800italic,900italic"]
    })

    const wrapperClasses = computed(() => {
      return route.meta.wrapperClasses || ["page-content","sub-banner"]
    })

    watch(atThreshold, (newVal, oldVal) => {
      if(newVal && !oldVal) {
        store.dispatch("screenThreshold", newVal)
      } else 
      if(!newVal && oldVal) {
        store.dispatch("screenThreshold", newVal)
      }
    }, {immediate:true})

    return { inBrowser, SSR, wrapperClasses }

  },

  components: {
    NavBar,
    Footer
  },
});
</script>


<style>.wf-force-outline-none[tabindex="-1"]:focus{outline:none;}</style>


<style>
:root {
  --main-blue: #0F8BC7;
  --darker-blue: #335a8a;
  --dark-text: #161a25;
  --dark-text-trans: rgba(82, 91, 115, 0.3);
  --med-text: #525b73;
  --light-text: #FFFFFF;
  --light-blue: #028ac8;
  --lighter-blue: #99e0ff;
  --midpage-background: #f7f7f7;
  --light-grey-text: #9098a1;
}

body {
    color: #121213;
    margin:0 !important;
    font-family: Dosis, sans-serif;
    font-size: 14px;
    line-height: 20px;
}
</style>

<template>

  <NavBar/>
  <router-view/> 
  <Footer/>

</template>

