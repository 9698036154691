
<script>
import { defineComponent, ref, computed, watch } from 'vue'
import AnimationLink from '@project/components/AnimationLink.vue'
import { actionSetOA2T, actionOA2T, animate } from '@kit/utils/Animate'
import { useStore } from 'vuex'
import { getCurrentYear } from '@kit/utils/Formats'
import NavLink from '@project/components/NavLink.vue'

export default defineComponent({
  name: "NavBar",
  setup() {
    const navMenu = ref(null)
    const store = useStore()

    let toggle = false

    const goIn = {
      translateY:"0%",
      easing:"easeOutQuad",
      duration:300
    }
    const goOut = {
      translateY:"-101%",
      easing:"easeOutQuad",
      duration:300
    }

    const navActions = actionSetOA2T([
      {
        name:"navIn",
        element(container) {
          return container
        },
        async action(container, snap) {
          goIn.duration = snap ? 0 : 300
          container.style.display = 'block'
          goIn.targets = container
          container.style.transform = "translateY(-101%)"
          await animate(goIn)
        }
      },
      {
        name:"navOut",
        element(container) {
          return container
        },
        async action(container, snap) {
          goOut.duration = snap ? 0 : 300
          goOut.targets = container
          container.style.transform = "translateX(0%)"
          await animate(goOut)
          container.style.display = 'none'
        }
      },
    ])

    const inMobile = computed(() => {
      return store.state.inMobile
    })

    //If it switches from mobile to desktop, then we're going to 
    //queue up another one to just snap the menu to closed.
    watch(inMobile, (newVal, oldVal) => {
      if(!newVal && oldVal) {
        toggle = false
        actionOA2T(navActions, "navOut", [navMenu.value, true])
      }
    })

    const navClicked = async() => {
      if(!toggle) {
        toggle = true
        actionOA2T(navActions, "navIn", [navMenu.value])
      } else {
        toggle = false
        actionOA2T(navActions, "navOut", [navMenu.value])
      }
    }

    return { navClicked, navMenu, inMobile, getCurrentYear }
  },
  components: {
    NavLink
  }
});
</script>


<template>

  <div data-collapse="small" data-animation="default" data-duration="400" data-doc-height="1" data-no-scroll="1" role="banner" class="navbar-left w-nav">
    <div class="menu-overlay">
      <div class="w-container">
        <NavLink to="/" class="logo-container w-nav-brand">
          <img src="/cms-static-assets/2023/06/5ffb63a8fce49b23a2442155_001-tree-black-silhouette-shape.svg" alt="" class="logo-icon" />
          <h1 class="logo-text">Golden Oaks</h1>
          <h2 class="logo-text subtitle nav">Motel</h2>
        </NavLink>
        <div class="menu-divider"></div>
        <div @click="navClicked" class="menu-button w-nav-button">
          <div class="w-icon-nav-menu"></div>
        </div>
        <nav v-if="!inMobile" role="navigation" class="nav-menu w-nav-menu">
          <NavLink to="/" class="nav-link w-nav-link">Home</NavLink>
          <NavLink to="/our-rooms" class="nav-link w-nav-link">Our Rooms</NavLink>
          <NavLink to="/events" class="nav-link w-nav-link">Events</NavLink>
          <NavLink to="/contact-us" class="nav-link w-nav-link">Contact us</NavLink>
          <a href="https://goldenoaksmotel.client.innroad.com/" class="nav-link w-nav-link">reservations</a>
          <div class="menu-divider"></div>
          <div class="nav-contact-block">
            <a href="#" class="nav-social-button w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb62312cee196d9da006f6_559f866bbcccd97730700260_Icon-facebook.png" alt="" class="nav-social-icon" />
            </a>
            <a href="#" class="nav-social-button w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb62312cee19a206a006f5_55d5b366fa59c51977887dd3_Icon-twitter.png" alt="" class="nav-social-icon" />
            </a>
            <a href="#" class="nav-social-button w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb62312cee1975caa006f4_55d5b37ab4ce9258656a0bde_Icon-linkedin-white.png" alt="" class="nav-social-icon" />
            </a>
            <a href="#" class="nav-social-button w-inline-block">
              <img src="/cms-static-assets/2023/06/5ffb667b0d861d100bea3303_001-instagram.svg" alt="" class="nav-social-icon" />
            </a>
            <div class="nav-bottom-text">Copyright {{ getCurrentYear() }} © Golden Oaks Motel.</div>
          </div>
        </nav>
      </div>
    </div>
    <div class="w-nav-overlay" style="display:block;">
      <nav ref="navMenu" role="navigation" class="nav-menu w-nav-menu">
        <NavLink to="/" class="nav-link w-nav-link">Home</NavLink>
        <NavLink to="/our-rooms" class="nav-link w-nav-link">Our Rooms</NavLink>
        <NavLink to="/events" class="nav-link w-nav-link">Events</NavLink>
        <NavLink to="/contact-us" class="nav-link w-nav-link">Contact us</NavLink>
        <a href="https://goldenoaksmotel.client.innroad.com/" class="nav-link w-nav-link">Reservations</a>
        <div class="menu-divider"></div>
        <div class="nav-contact-block">
          <a href="#" class="nav-social-button w-inline-block">
            <img src="/cms-static-assets/2023/06/5ffb62312cee196d9da006f6_559f866bbcccd97730700260_Icon-facebook.png" alt="" class="nav-social-icon" />
          </a>
          <a href="#" class="nav-social-button w-inline-block">
            <img src="/cms-static-assets/2023/06/5ffb62312cee19a206a006f5_55d5b366fa59c51977887dd3_Icon-twitter.png" alt="" class="nav-social-icon" />
          </a>
          <a href="#" class="nav-social-button w-inline-block">
            <img src="/cms-static-assets/2023/06/5ffb62312cee1975caa006f4_55d5b37ab4ce9258656a0bde_Icon-linkedin-white.png" alt="" class="nav-social-icon" />
          </a>
          <a href="#" class="nav-social-button w-inline-block">
            <img src="/cms-static-assets/2023/06/5ffb667b0d861d100bea3303_001-instagram.svg" alt="" class="nav-social-icon" />
          </a>
          <div class="nav-bottom-text">Copyright {{ getCurrentYear() }} © Golden Oaks Motel.</div>
        </div>
      </nav>
    </div>
  </div>

</template>