export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue')
  },
  {
    name: 'contact-us',
    path: '/contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */'@pages/Contact.vue')
  },
  {
    name: 'events',
    path: '/events',
    component: () => import(/* webpackChunkName: "events" */'@pages/Events.vue')
  },
  {
    name: 'our-rooms',
    path: '/our-rooms',
    component: () => import(/* webpackChunkName: "events" */'@pages/OurRooms.vue')
  }
]