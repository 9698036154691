<script>
import { defineComponent, computed } from "vue"
import { useRoute } from 'vue-router'

export default defineComponent({
  props: ["to", "class"],
  
  name: "NavLink",
  setup(props) {

    const route = useRoute()
    const isCurrent = computed(() => {
      return route.path == props.to
    })
    const linkClass = computed(() => {
      return isCurrent.value ? props.class+' w--current' : props.class
    })

    return { linkClass, isCurrent }
  }
})
</script>

<template>
  <router-link :to="to" :aria-current="isCurrent ? 'page' : null" :class="linkClass"><slot></slot></router-link>  
</template>
