<script>
import { defineComponent } from "vue"

export default defineComponent({  
  name: "SideNavDrawer",
})
</script>

<template>
  <div></div>  
</template>
